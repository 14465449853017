import React from 'react';
import './styles.scss';
import { CloudDownload } from '@styled-icons/boxicons-solid/CloudDownload';
import { WorkHistoryMock } from './work-history-mock';
import { EducationMock } from './education-mock';

export default function Resume() {

    function renderTimeline() {
        const list = WorkHistoryMock;
        return list.map((work, index) => (
            <div className={`box ${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
                <span className="period">{work.period}</span>
                <div className="content">
                    <h5>{work.title} at <a href={work.compannyWebsite} rel="noreferrer" target="_blank">{work.companyName}</a></h5>
                    {work.description ? <p dangerouslySetInnerHTML={{__html: work.description}}></p> : null} 
                    {work.technologies ? <p><b>Technologies I used:</b> {work.technologies}</p> : null}                
                </div>
            </div>
        ));
    }

    function renderEducation() {
        const list = EducationMock;
        return list.map((education, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
                <div className="education-box">
                    <img src={education.image} alt={education.alt} />
                    <h5 className="title">{education.title}</h5>
                    <p className="subtitle">{education.subtitle}</p>
                </div>
            </div>
        ));
    }

    return (
        <div className="container background-dark">
            <div className="title-section">
                <h1 className="title-page">Resume</h1>
            </div>

            <div className="section-head">
                <h2><span>My professional</span>
                    Work History
                </h2>
                <a className="btn btn-primary btn-download fade-in" href='Felipe_Chan_Resume.pdf' rel="noopener noreferrer" target="_blank">
                    <CloudDownload className="icon" size="20" />
                    Download PDF Resume
                </a>
            </div>

            <div className="timeline">
                {renderTimeline()}
            </div>

            <div className="section-head">
                <h3><span>My Educational</span>
                    Background History</h3>
            </div>

            <div className="row row-margin education-section">
                {renderEducation()}
            </div>
        </div>
    )
}
