import React, { useEffect, useState } from 'react';
import { PersonFill } from '@styled-icons/bootstrap/PersonFill';
import { Envelope } from '@styled-icons/boxicons-solid/Envelope';
import './styles.scss';
import { useHistory } from 'react-router-dom';

function wait(time: number) {
    return new Promise(resolve => setTimeout(resolve, time));
}

export default function Menu() {
    const history = useHistory();
    const greetings = 'Hello World!';
    const myNameIs = "I'm Felipe Chan"
    const waitTime = 150;

    const [state, setState] = useState({
        title: '',
        subtitle: '',
        finishedTitle: false
    });

    const typeWriter = async () => {
        if (state.title.length < greetings.length) {
            await wait(waitTime);
            setState({ ...state, title: state.title + greetings.charAt(state.title.length) });
            return;
        }
        if (state.subtitle.length === 0) {
            await wait(500);
        }
        if (state.subtitle.length < myNameIs.length) {
            await wait(waitTime);
            setState({ ...state, subtitle: state.subtitle + myNameIs.charAt(state.subtitle.length) });
            return;
        }
        setState({ ...state, finishedTitle: true });
    }

    useEffect(() => {
        typeWriter();
    }, [state.title, state.subtitle]);


    const goToPage = (path: string) => {
        history.push(path);
    }

    return (
        <div className="background-home">
            <div className="title">
                <h2>{state.title}</h2>
                <h1>{state.subtitle}</h1>
                {state.finishedTitle && (<div className="home-options">
                    <button className="btn btn-primary fade-in" onClick={() => goToPage('/about')}><PersonFill className="icon" size="16" />Meet me</button>
                    <button className="btn btn-light-primary fade-in" onClick={() => goToPage('/contact')}><Envelope className="icon" size="16" />Contact me</button>
                </div>)}
            </div>
        </div>
    );
}
