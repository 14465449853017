export const WorkHistoryMock = [
    {
        period: 'March 2021 - Present',
        title: 'Software Engineer',
        companyName: 'BairesDev',
        compannyWebsite: 'https://www.bairesdev.com/',
        description: 'I develop front-end and back-end features for the "Engagement & Loyalty" platform of the client zinnia.com (formerly Life.io), deepening the relationship between insurers and customers, building brand awareness to grow business.',
        technologies: 'Vue.js, Node.js, Moleculer.js, PostgreSQL'
    },
    {
        period: 'September 2021 - Nov 2022',
        title: 'Co-founder & CTO',
        companyName: 'Meegu',
        compannyWebsite: 'https://www.meegu.com.br/',
        description: `Meegu is a digital platform to manage group subscription plans (TV, Music streaming and gaming platforms). We help users to split bills and manage group communication with friends.
        <br/>
        I got in the business with the goal of helping a friend build a startup from scratch by hiring a qualified technology team, draw and implement a robust and scalable architecture, initiate front-end and back-end projects and develop some features that today are being used by thousands of customers in Brazil (and soon worldwide).`,
        technologies: 'React.js, Node.js, Serverless Framework, Nest.js, AWS (ECS, EC2, S3, API Gateway, Load Balancer, CloudFront, RDS, Route 53, DynamoDB, Lambda), MySQL'
    },
    {
        period: 'May 2019 - March 2021',
        title: 'Full Stack Software Engineer',
        companyName: 'Férias & Co.',
        compannyWebsite: 'https://www.ferias.co/',
        description: 'Through the union of thousands of hotels in Brazil and worldwide, Férias & Co. is a startup with a platform that offers the possibility for companies to encourage employees with a corporate benefit where they can enjoy their days of rest and leisure with their families booking flights and nice hotel rooms when they go on vacation.',
        technologies: 'Angular 8, Node.js, MongoDB, Serverless Framework, AWS, Docker, .NET Core.'
    },
    {
        period: 'June 2017 - May 2019',
        title: 'Full Stack Software Engineer',
        companyName: 'Duratex S.A',
        compannyWebsite: 'https://www.duratex.com.br/',
        description: 'The eighth largest producer of wood panels in the world and the largest in the Southern Hemisphere, Duratex manufactures products aimed at the furniture industry and the civil construction sectors',
        technologies: 'Angular 4, Ionic 2, Node.js, MongoDB, SQL Server, Serverless Framework, AWS, Docker, .NET 4'
    },
    {
        period: 'April 2016 - June 2017',
        title: 'Systems Developer Intern',
        companyName: 'Duratex S.A',
        compannyWebsite: 'https://www.duratex.com.br/',
        description: "This is where everything starts in my Tech Carrear. I joined the development team of Duratex to build and support company's systems.",
        technologies: 'AngularJS, Angular 2, Ionic 1, Node.js, MongoDB, SQL Server, ASP .NET, VB6.'
    },
    {
        period: 'November 2014 - October 2015',
        title: 'Graphic Designer',
        companyName: 'Buonny',
        compannyWebsite: 'https://www.buonny.com.br/',
        description: 'One of the biggest risk management companies in cargo transportation in Brazil, for this company I created a lot of marketing campaigns using one of my biggest strengths: creativity. I have played the role of Graphic Designer in the Marketing sector of Buonny.',
        technologies: 'Adobe Photoshop, Illustrator, InDesign, Premiere, After Effects.'
    },
];