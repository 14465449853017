import React from 'react';
import { Map } from '@styled-icons/boxicons-regular/Map';
import GuyProgrammingImg from '../../assets/images/computer-guy.png';
import { Suitcase } from '@styled-icons/remix-line/Suitcase';
import { Layer } from '@styled-icons/boxicons-regular/Layer';
import { HappyHeartEyes } from '@styled-icons/boxicons-regular/HappyHeartEyes';
import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt';
import { Layout } from '@styled-icons/boxicons-regular/Layout';
import { FileEarmarkTextFill } from '@styled-icons/bootstrap/FileEarmarkTextFill';
import { InfoCircle } from '@styled-icons/boxicons-solid/InfoCircle';
import './styles.scss';
import { useHistory } from 'react-router-dom';

export default function About() {
    const history = useHistory();
    const navigateToResume = () => {
        window.scrollTo(0, 0)
        history.push('/work-and-education');
    }

    const yearsOfExperience = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        const yearIStarted = new Date(2016, 3, 1).getFullYear();
        const monthIStarted = new Date(2016, 3, 1).getMonth();
        const dayIStarted = new Date(2016, 3, 1).getDate();

        let calculatedTime = currentYear - yearIStarted;

        if (currentMonth < monthIStarted - 1) {
            calculatedTime--;
        }
        if (monthIStarted - 1 === currentMonth && currentDay < dayIStarted) {
            calculatedTime--;
        }
        return calculatedTime;
    }

    return (
        <div className="container background-dark">
            <div className="title-section">
                <h1 className="title-page">About me</h1>
            </div>
            <div className="about-description-container row order-lg-1">
                <div className="about-description-section">
                    <h2>Felipe S. Chan</h2>
                    <p>Located in: <a href="https://www.google.com/maps/place/S%C3%A3o+Paulo,+SP/@-23.6821604,-46.8754836,10z/data=!3m1!4b1!4m5!3m4!1s0x94ce448183a461d1:0x9ba94b08ff335bae!8m2!3d-23.5505199!4d-46.6333094" rel="noreferrer" target="_blank"><Map size="15" />Sao Paulo, Brazil</a> 🇧🇷</p>
                    <div className="about-description">
                        <p>I am a Brazillian Full Stack Software Engineer.<br />
                            On my trajectory, I have worked in many digital projects always trying to follow coding best practices to build systems with more integrity, best performance and good maintainability.
                            During my careear, I have built complex and scalable solutions for clients from small startups to large companies.
                        </p>
                        <p>I try to pay attention to every detail of systems I work to develop it with the best user experience, quality and security possible.</p>
                        <p>My native language is Portuguese, but I'm also an English speaker.</p>
                    </div>
                </div>
                <div className="description-summary order-lg-3 fade-in">
                    <div className="summary row">
                        <div className="icon">
                            <Suitcase size="20" />
                        </div>
                        <div className="icon-info">
                            <h6>+{yearsOfExperience()} Years</h6>
                            <p>Experience</p>
                        </div>
                    </div>
                    <div className="summary row">
                        <div className="icon">
                            <Layer size="20" />
                        </div>
                        <div className="icon-info">
                            <h6>+10 projects</h6>
                            <p>Delivered</p>
                        </div>
                    </div>
                    <div className="summary row">
                        <div className="icon">
                            <HappyHeartEyes size="20" />
                        </div>
                        <div className="icon-info">
                            <h6>5 happy clients</h6>
                            <p>Work experience</p>
                        </div>
                    </div>
                </div>

                <div className="icon-guy-programming order-lg-2">
                    <img src={GuyProgrammingImg} alt="Programming" className='fade-in' />
                </div>

            </div>

            <div className="special-block">
                <div className="special-title">
                    <h2>My Services</h2>
                    <span>What I Actually Do</span>
                </div>
                <div className="row row-margin">
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        <div className="service-icon">
                            <Layout size="40" />
                        </div>
                        <h4>Front-end development</h4>
                        <p>Responsible for a website’s user-facing code and the architecture of its immersive user experiences.
                            Everything that you see when you’re navigating around the Internet, from fonts and colors to dropdown menus and sliders, is part of a Front-end developer's job.
                        </p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        <div className="service-icon">
                            <CodeAlt size="40" />
                        </div>
                        <h4>Back-end development</h4>
                        <p>Handle the ‘behind-the-scenes’ functionality of front-end applications. It connects an interface to a database, manages user
                            connections, and powers the web application itself. Back-end development works with the front end to deliver the final product.</p>
                    </div>
                </div>
            </div>

            <div className="special-block" id="professional-skills">
                <div className="section-head">
                    <h3><span>Technologies</span>
                        I can work with</h3>
                </div>
                <div>
                    <h4>Front-end</h4>
                    <p className="margin-bottom-30">
                        <img src="https://img.shields.io/badge/-HTML5-E34F26?style=flat-square&logo=html5&logoColor=white" alt="HTML" />
                        <img src="https://img.shields.io/badge/-CSS3-1572B6?style=flat-square&logo=css3" alt="CSS" />
                        <img src="https://img.shields.io/badge/-JavaScript-black?style=flat-square&logo=javascript" alt="javascript" />
                        <img src="https://img.shields.io/badge/-TypeScript-94BAFF?style=flat-square&logo=typescript" alt="typescript" />
                        <img src="https://img.shields.io/badge/-React.js-black?style=flat-square&logo=react" alt="react" />
                        <img src="https://img.shields.io/badge/-Angular-DD0031?style=flat-square&logo=angular" alt="angular" />
                        <img src="https://img.shields.io/badge/-Vue.js-339933?style=flat-square&logo=vue" alt="vue" />
                        <img src="https://img.shields.io/badge/-Sass-CC6699?style=flat-square&logo=sass&logoColor=white" alt="SASS" />
                        <img src="https://img.shields.io/badge/-Bootstrap-563D7C?style=flat-square&logo=bootstrap" alt="bootstrap" />
                        <img src="https://img.shields.io/badge/-Next.js-black?style=flat-square&logo=next" alt="Nextjs" />
                        <img src="https://img.shields.io/badge/-Ionic-3880FF?style=flat-square&logo=ionic&logoColor=white" alt="Ionic" />
                    </p>
                    <h4>Back-end</h4>
                    <p className="margin-bottom-30">
                        <img src="https://img.shields.io/badge/-Nodejs-339933?style=flat-square&logo=Node.js&logoColor=white" alt="Node" />
                        <img src="https://img.shields.io/badge/-NestJS-ea2845?style=flat-square&logo=nestjs" alt="Nest" />
                        <img src="https://img.shields.io/badge/-Docker-2496ED?style=flat-square&logo=docker&logoColor=white" alt="Docker" />
                        <img src="https://img.shields.io/badge/-MongoDB-black?style=flat-square&logo=mongodb" alt="MongoDB" />
                        <img src="https://img.shields.io/badge/-Moleculer-2496ED?style=flat-square&logo=moleculer&logoColor=white" alt="Moleculer" />
                        <img src="https://img.shields.io/badge/-CSharp-339933?style=flat-square&logo=csharp&logoColor=white" alt="Moleculer" />
                        <img src="https://img.shields.io/badge/-MySQL-4479A1?style=flat-square&logo=mysql&logoColor=white" alt="MySQL" />
                        <img src="https://img.shields.io/badge/-SQL%20Server-CC2927?style=flat-square&logo=microsoft-sql-server&logoColor=white" alt="SQL Server" />
                    </p>


                    <h4>Cloud Services</h4>
                    <p>
                        <img src="https://img.shields.io/badge/-AWS-black?style=flat-square&logo=amazon" alt="AWS" /> : 
                        (ECS, EC2, S3, API Gateway, Load Balancer, CloudFront, RDS, Route 53, DynamoDB, Lambda)</p>

                    <p><img src="https://img.shields.io/badge/-Vercel-black?style=flat-square&logo=vercel" alt="Vercel" /></p>
                    <p className="margin-bottom-30"><img src="https://img.shields.io/badge/-Heroku-563D7C?style=flat-square&logo=heroku" alt="heroku" /></p>
                    <h4>Tools</h4>
                    <p className="margin-bottom-30">
                        <img src="https://img.shields.io/badge/-Git-black?style=flat-square&logo=git" alt="git" /> 
                        <img src="https://img.shields.io/badge/-GitHub-181717?style=flat-square&logo=github" alt="github" /> 
                        <img src="https://img.shields.io/badge/-Figma-black?style=flat-square&logo=figma&logoColor=white" alt="Figma" /> 
                        <img src="https://img.shields.io/badge/-BitBucket-darkblue?style=flat-square&logo=bitbucket" alt="bitbucket" /> 
                        <img src="https://img.shields.io/badge/-Jira-0052CC?style=flat-square&logo=jira" alt="jira" /> 
                        <img src="https://img.shields.io/badge/-ClickUp-fafbfc?style=flat-square&logo=clickup" alt="Clickup" /> 
                        <img src="https://img.shields.io/badge/-VSCode-007ACC?style=flat-square&logo=visual-studio-code&logoColor=white" alt="VSCode" /> 
                    </p>
                </div>
                <div className="alert alert-info">
                    <span><InfoCircle className="icon" size="20" /><b>Fun fact:</b> This website was made by me using React.js.</span>
                </div>
            </div>

            <button className="btn btn-light btn-lg call-to-action-resume" onClick={() => navigateToResume()}><FileEarmarkTextFill size="15" /> Checkout my Resume</button>
        </div>
    )
}
