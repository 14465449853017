import ImgFiap from '../../assets/images/fiap.png';
import ImgImpacta from '../../assets/images/impacta.png';
import ImgCultura from '../../assets/images/cultura.png';
import ImgCebrac from '../../assets/images/cebrac.png';

export const EducationMock = [
    { title: "Bachelor's degree in Information Systems", subtitle: 'Graduated in 2019', image: ImgFiap, alt: 'FIAP' },
    { title: 'Microsoft SQL Server 2014 Course', subtitle: 'December, 2015', image: ImgImpacta, alt: 'Impacta Tecnologia' },
    { title: 'English Course', subtitle: '2011-2014', image: ImgCultura, alt: 'Cultura Inglesa' },
    { title: 'Maintenance of computers', subtitle: 'December, 2011', image: ImgCebrac, alt: 'Cebrac' },
];